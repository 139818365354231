import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Niranjan Powade </span>
            from <span className="purple"> Pune, India.</span>
            <br /> A recent Computer Science graduate, and I'm passionate about
            building exceptional experiences using REACT.
            <br />
            <br />
            Currently, I'm putting my skills to work as a Software Developer
            at Spacious Tech in Pune.
            <br />
            <br />
            In addition to coding, there are several other activities that I
            have a deep passion for!
            <br />
            <ul>
              <br />
              <li>Playing Football</li>
              <li>Reading Books</li>
              <li>Travelling</li>
            </ul>
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
