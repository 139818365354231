import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import editor from "../../Assets/Projects/cabapp.jpg";
import chatapp from "../../Assets/Projects/chatapp.png";
import Aiapp from "../../Assets/Projects/Ai.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatapp}
              isBlog={false}
              title="Let’s Chat (Real Time Chat Application)"
              description="This web messenger project is a real-time communication platform that allows users to easily connect and chat with each other. Utilizing web technologies such as HTML, CSS, JavaScript and Node JS the messenger features a user-friendly interface.This web messenger application serves as a bridge to connect individuals, empowering them to engage in instant and meaningful exchanges regardless of geographical distances."
              ghLink=""
              demoLink="https://lets-chat-webmessenger.000webhostapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="FastCab"
              description="FastCab is a taxi booking application developed using React Native for cities without popular ride-hailing apps like Ola. User-friendly interface for smooth navigation and intuitive user experience."
              ghLink=""
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Aiapp}
              isBlog={false}
              title="AI-based Workout Assistant and Fitness guide"
              description=" An application that detects the user’s exercise pose counts the specified exercise repetitions and provides personalized,
detailed analysis about improving the user’s body posture. This is an AI-based Workout Assistant and Fitness guide to guide people who don’t have access to the gym but are still
willing to work out at home to maintain their physique and fitness and keep their body in good shape.To help them perform the exercises correctly and prevent them from immediate injuries."
              ghLink=""
              demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
